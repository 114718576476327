import React, { useState, useEffect } from 'react';
import "./css/portfolio.css";
import PortfolioItem from './PortfolioItem';

function Portfolio({ type, apiEndpoint, statsFields, itemFields }) {
    const [record, setRecord] = useState(null);
    const [customers, setCustomers] = useState([]);
    const [animatedValues, setAnimatedValues] = useState(
        Object.fromEntries(statsFields.map(field => [field.key, 0]))
    );

    useEffect(() => {
        document.title = `${type} Portfolio | PV Solar`;
        fetchRecord();
        fetchCustomers();
    }, []);

    function animateValue(key, end, duration) {
        if (isNaN(end)) return;
        let start = animatedValues[key];
        let range = end - start;
        let minTimer = 50;
        let stepTime = Math.abs(Math.floor(duration / range));
        stepTime = Math.max(stepTime, minTimer);
        let startTime = new Date().getTime();
        let endTime = startTime + duration;
      
        let timer = setInterval(() => {
          let now = new Date().getTime();
          let remaining = Math.max((endTime - now) / duration, 0);
          let value = Math.round(end - (remaining * range));
          
          setAnimatedValues(prev => ({ ...prev, [key]: value }));
      
          if (value === end) {
            clearInterval(timer);
          }
        }, stepTime);
    }

    async function fetchRecord() {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/record/sum/${apiEndpoint}`);
            if (!response.ok) {
                console.error(`An error has occurred: ${response.statusText}`);
                return;
            }
            const record = await response.json();
            setRecord(record);
            if (record) {
                statsFields.forEach(field => {
                    animateValue(field.key, record[field.key], 1500);
                });
            }
        } catch (error) {
            console.error(error);
        }
    }

    async function fetchCustomers() {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/record/special-customers/${apiEndpoint}`);
            if (!response.ok) {
                console.error(`An error has occurred: ${response.statusText}`);
                return;
            }
            let customers = await response.json();
            customers = customers.map(customer => ({
                ...customer,
                imageUrl: customer.image || sun
            }));
            setCustomers(customers);
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className="portfolio">
            <div className="portfolio-header">
                <h1 className="title">{type} Portfolio</h1>
                <p className="subtitle">
                    {statsFields.map((field, index) => (
                        <span key={field.key}>
                            {animatedValues[field.key].toLocaleString()} {field.label}
                            {index < statsFields.length - 1 && " | "}
                        </span>
                    ))}
                </p>
            </div>
            <div className="portfolio">
                {customers.map((customer, index) => (
                    <PortfolioItem
                        key={index}
                        src={customer.imageUrl}
                        alt={`Image ${index + 1}`}
                        city={`${customer.address.city}, ${customer.address.state}`}
                        {...Object.fromEntries(
                            itemFields.map(field => [
                                field.key,
                                { value: customer[field.valueKey] / (field.divisor || 1), text: field.label }
                            ])
                        )}
                    />
                ))}
            </div>
        </div>
    );
}

// Residential Portfolio
export function ResidentialPortfolio() {
    return (
        <Portfolio
            type="Residential"
            apiEndpoint="residential"
            statsFields={[
                { key: "totalPanels", label: "panels" },
                { key: "totalWatts", label: "kW AC" },
                { key: "totalKva", label: "kVA of battery power" },
                { key: "totalWattHours", label: "kWh of energy storage" },
                { key: "totalEnergy", label: "MWh generated" }
            ]}
            itemFields={[
                { key: "anotherText", valueKey: "size_w", label: "kW", divisor: 1000 },
                { key: "description", valueKey: "modules", label: "Panels" },
                { key: "battery", valueKey: "battery_capacity_wh", label: "kWh", divisor: 1000 }
            ]}
        />
    );
}

// Commercial Portfolio
export function CommercialPortfolio() {
    return (
        <Portfolio
            type="Commercial"
            apiEndpoint="commercial"
            statsFields={[
                { key: "totalPanels", label: "panels" },
                { key: "totalWatts", label: "kW AC" },
                { key: "totalEnergy", label: "MWh generated" }
            ]}
            itemFields={[
                { key: "anotherText", valueKey: "size_w", label: "Watts" },
                { key: "description", valueKey: "modules", label: "Panels" }
            ]}
        />
    );
}