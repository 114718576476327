import React, { useState, useEffect, useRef } from "react";
import "./css/home.css";
import image from "./media/panel.jpg";
import solar from "./media/solar.mp4";
import emailjs from 'emailjs-com';
import Video from "./video";
import { Link } from 'react-router-dom';

export default function Home() {
  const [record, setRecord] = useState(null);
  const [customers, setCustomers] = useState([]);
  
  const numberRef = useRef(null);
  const [form, setForm] = useState({
    name: "",
    email: "",
    password: "",
    status: ""
  });

  const [animatedValues, setAnimatedValues] = useState({
    totalPanels: 0,
    totalWatts: 0,
    totalEnergy: 0,
    totalWattHours: 0,
    totalKva: 0
  });

  // Near the top of your component
  const recordRef = useRef(null);
  // Add this near the top of your component
  const animationTimers = useRef({});
  const animationCooldowns = useRef({});

  useEffect(() => {
    // Set document title
    document.title = "Solar Panels & Energy Storage | PV Solar";
    
    // Fetch data
    fetchRecord();
    
    // Set up Intersection Observer for animation on scroll
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting && recordRef.current) {
          const key = entry.target.dataset.animationKey;
          const record = recordRef.current;
          
          switch(key) {
            case "totalPanels":
              animateValue(key, record.totalPanels, 1500);
              break;
            case "totalWatts":
              animateValue(key, record.totalWatts, 1500);
              break;
            case "totalWattHours":
              animateValue(key, record.totalWattHours, 1500);
              break;
            case "totalEnergy":
              animateValue(key, record.totalEnergy, 1500);
              break;
            case "totalKva":
              animateValue(key, record.totalKva, 1500);
              break;
          }
        }
      });
    }, { threshold: 0.3 });
    
    // Wait briefly to ensure elements are rendered before observing
    setTimeout(() => {
      const elements = document.querySelectorAll('[data-animation-key]');
      elements.forEach(el => observer.observe(el));
    }, 100);
    
    // Cleanup function
    return () => {
      const elements = document.querySelectorAll('[data-animation-key]');
      elements.forEach(el => observer.unobserve(el));
    };
  }, []); // Empty dependency array means this runs once on component mount

  function updateForm(value) {
    return setForm((prev) => {
      return { ...prev, ...value };
    });
  }

  function sendEmail(e) {
    e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it
  
    emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, e.target, process.env.REACT_APP_USER_ID)
      .then((result) => {
          window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
      }, (error) => {
          console.log(error.text);
      });
  }

  // Then modify your animateValue function
  function animateValue(key, end, duration) {
    if (isNaN(end)) return;
    
    // Clear any existing animation for this key
    if (animationTimers.current[key]) {
      clearInterval(animationTimers.current[key]);
    }
    
    // Check if in cooldown period (avoid reanimating same value too quickly)
    const now = new Date().getTime();
    if (animationCooldowns.current[key] && now - animationCooldowns.current[key] < 2000) {
      return;
    }
    
    console.log(`Animating ${key} to ${end}`);
    let start = animatedValues[key];
    let range = end - start;
    
    // If range is very small, just set the value directly
    if (Math.abs(range) < 10) {
      setAnimatedValues(prevState => ({ ...prevState, [key]: end }));
      return;
    }
    
    let minTimer = 50;
    let stepTime = Math.abs(Math.floor(duration / range));
    stepTime = Math.max(stepTime, minTimer);
    let startTime = new Date().getTime();
    let endTime = startTime + duration;

    let timer = setInterval(() => {
      let now = new Date().getTime();
      let remaining = Math.max((endTime - now) / duration, 0);
      let value = Math.round(end - (remaining * range));
      
      setAnimatedValues(prevState => ({ ...prevState, [key]: value }));

      if (value === end) {
        clearInterval(timer);
        // Set cooldown timestamp
        animationCooldowns.current[key] = new Date().getTime();
      }
    }, stepTime);
    
    // Store the timer reference
    animationTimers.current[key] = timer;
  }

  async function fetchRecord() {
    console.log(process.env.REACT_APP_API_URL);
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/record/total`);
        
        if (!response.ok) {
            const message = `An error has occurred: ${response.statusText}`;
            console.error(message);
            return;
        }

        const record = await response.json();
        console.log(record);
        setRecord(record);
        recordRef.current = record;
        if (record) {
          animateValue("totalPanels", record.totalPanels, 1500);
          animateValue("totalWatts", record.totalWatts, 1500);
          animateValue("totalWattHours", record.totalWattHours, 1500);
          animateValue("totalEnergy", record.totalEnergy, 1500);
          animateValue("totalKva", record.totalKva, 1500)
        }
        
    } catch (error) {
        console.error(error);
    }
  }

  return (
  <div className="main-content">
    <div className="home-container">
      <div className="video-foreground">
          <Video/>
          <img src={image}></img>
      </div>

      <div className="site-content">
          <div className="centered-text">
              Illuminating Futures with Sustainable Solar Solutions
          </div>
          <a className="corner-text" href="https://www.cslb.ca.gov/OnlineServices/CheckLicenseII/LicenseDetail.aspx?LicNum=1107091">
            CA C-10 License #1107091
          </a>
      </div>
    </div>

    <div className="full-width-block">
        <div className="text-wrapper" ref={numberRef}>
            <div className="text-container">
                <p data-animation-key="totalPanels">{animatedValues.totalPanels.toLocaleString()}</p>
                <span>Total Panels</span>
            </div>
            <div className="text-container">
                <p data-animation-key="totalWatts">{(animatedValues.totalWatts || 0).toLocaleString()}</p>
                <span>Total kW AC</span>
            </div>
            <div className="text-container">
                <p data-animation-key="totalEnergy">{(animatedValues.totalEnergy || 0).toLocaleString()}</p>
                <span>MWh Generated</span>
            </div>
            <div className="text-container">
                <p data-animation-key="totalKva">{(animatedValues.totalKva || 0).toLocaleString()}</p>
                <span>kVA of Storage</span>
            </div>
            <div className="text-container">
                <p data-animation-key="totalWattHours">{(animatedValues.totalWattHours || 0).toLocaleString()}</p>
                <span>kWh of Storage</span>
            </div>
            
        </div>
    </div>

    <div className="home-form-block">
      <div className="side-container">
            <h1>Unlock your savings.</h1>
            <div className="info-text">
            Explore our range of products and services to understand what we offer. 
            Delve into local incentives that can benefit you. 
            Additionally, uncover various financing options tailored to meet your needs.
            </div>
        </div>

      <div className="home-form-container">
        <form onSubmit={sendEmail}>
          <div className="form-group">
            <label>First Name*</label>
            <input
              type="text"
              className="form-control"
              id="to_first_name"
              name="to_first_name"
              value={form.first_name}
              // onChange={(e) => updateForm({ first_name: e.target.value })}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="name">Last Name*</label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="to_last_name"
              value={form.last_name}
              onChange={(e) => updateForm({ last_name: e.target.value })}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email*</label>
            <input
              type="text"
              className="form-control"
              id="email"
              name="to_email"
              value={form.email}
              onChange={(e) => updateForm({ email: e.target.value })}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="phone">Phone*</label>
            <input
              type="phone"
              className="form-control"
              id="phone"
              name="to_phone"
              value={form.phone}
              onChange={(e) => updateForm({ phone: e.target.value })}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="address">Address*</label>
            <input
              type="address"
              className="form-control"
              id="address"
              name="to_address"
              value={form.address}
              onChange={(e) => updateForm({ address: e.target.value })}
              required
            />
          </div>

          <div className="form-group checkbox-container">
            <label>
              <input
                type="checkbox"
                name="isReferral"
                checked={form.isReferral}
                onChange={(e) => updateForm({ isReferral: e.target.checked })}
              />
              I was referred by someone
            </label>
          </div>

          {form.isReferral && (
            <div className="form-group">
              <label htmlFor="referrer">Referrer's First and Last Name</label>
              <input
                type="text"
                className="form-control"
                id="referrer"
                name="to_referrer"
                value={form.referrer}
                onChange={(e) => updateForm({ referrer: e.target.value })}
              />
            </div>
          )}

          <div className="form-button">
            <input
              type="submit"
              value="Contact Us"
              className="btn btn-primary"
            />
          </div>
        </form>
      </div>  
    </div>
  </div>
    );
}