import React from 'react';
import './css/DesignInstallation.css';

const DesignInstallation = () => {
  return (
    <section className="design-installation">
      {/* Background Image Container */}
      <div className="background-container">
      </div>
      
      {/* Content Container */}
      <div className="content-container">
        <h1 className="design-title">Design & Installation</h1>
        
        <p className="main-description">
          At PV Solar, we deliver expertly crafted solar solutions tailored to your needs. 
          From initial design to final installation, our process ensures efficiency, reliability, and sustainability.
        </p>
        
        <div className="service-details">
          <h2 className="design-subtitle">Why Choose Us?</h2>
          <ul>
            <li>
              <span className="check-icon">✓</span>
              <span>Custom solar system designs optimized for maximum energy output</span>
            </li>
            <li>
              <span className="check-icon">✓</span>
              <span>Professional installation adhering to the highest industry standards</span>
            </li>
            <li>
              <span className="check-icon">✓</span>
              <span className="highlighted">Certified Expertise: Overseen by a Professional Engineer (PE) licensed in Electrical Power in California</span>
            </li>
          </ul>
        </div>
        
        <button 
        className="request-button"
        onClick={() => window.location.href = '/solar-quote'}
        >Request a Quote
        </button>
      </div>
      
      {/* Services Cards Section */}
      <div className="services-section">
        <div className="services-grid">
          {/* Residential Card */}
          <div className="service-card">
            <h3>Residential Solutions</h3>
            <p>Maximize your home's energy efficiency with our custom residential solar systems. Reduce utility bills and increase property value.</p>
            <div className="image-container residential"></div>
          </div>
          
          {/* Commercial Card */}
          <div className="service-card">
            <h3>Commercial Projects</h3>
            <p>Scale your business sustainably with our commercial solar installations. Custom designed systems for maximum ROI and energy independence.</p>
            <div className="image-container commercial"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DesignInstallation;