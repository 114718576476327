import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Fix for default marker icons in Leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

const AddressMap = () => {
  const [addresses, setAddresses] = useState([]);
  
  useEffect(() => {
    fetch('/addresses.json')
      .then(response => response.json())
      .then(data => {
        setAddresses(data);
      })
      .catch(err => {
        console.error("Error loading addresses:", err);
      });
  }, []);

  // San Diego coordinates as default
  const defaultPosition = [32.720, -117.1625];

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <MapContainer 
        center={defaultPosition} 
        zoom={11} 
        style={{ height: '100%', width: '100%' }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        
        {addresses.map((address, index) => (
          <Marker 
            key={index} 
            position={[address.latitude, address.longitude]}
          >
            <Popup>
              {address.street1}<br />
              {address.city}, {address.state} {address.postal_code}
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
};

export default AddressMap;