import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import Navbar from "./components/navbar";
import GetQuote from "./components/getQuote";
import Home from "./components/home";
import About from "./components/about";
import Footer from "./components/footer";
import Battery from "./components/battery";
import DesignInstallation from "./components/design";
import Maintenance from "./components/maintenance";
import Refer from "./components/refer";
import { ResidentialPortfolio, CommercialPortfolio } from './components/Portfolio';

const App = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize("G-WHCSWLK15B");
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
    // Dynamically set canonical tag
    const canonicalUrl = `https://www.pvsolar.energy${location.pathname}`;
    let link = document.querySelector("link[rel='canonical']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "canonical";
      document.head.appendChild(link);
    }
    link.href = canonicalUrl;
  }, [location]);

  return (
    <div>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/solar-quote" element={<GetQuote />} />
        <Route path="/residential" element={<ResidentialPortfolio />} />
        <Route path="/commercial" element={<CommercialPortfolio />} />
        <Route path="/about" element={<About />} />
        <Route path="/design" element={<DesignInstallation />} />
        {/* <Route path="/battery-storage" element={<Battery />} /> */}
        <Route path="/maintenance" element={<Maintenance />} />
        <Route path="/refer-and-earn" element={<Refer />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;