import React from 'react';

function PortfolioItem({ src, alt, city, description, anotherText, battery }) {
    return (
        <div className="portfolio-item">
            <img src={src} alt={alt} />
            <div className="item-content">
                <p className="city">{city}</p>
                <div className="flex-container">
                    <span className="another-text">
                        <strong>{anotherText.value}</strong> {anotherText.text}
                    </span>
                    <span className="bullet">•</span>
                    <span className="description">
                        <strong>{description.value}</strong> {description.text}
                    </span>
                    {battery && battery.value > 0 && (
                        <>
                            <span className="bullet">•</span>
                            <span className="battery">
                                <strong>{battery.value}</strong> {battery.text}
                            </span>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default PortfolioItem;