import React from 'react';
import "./css/about.css";
import solar from "./media/solar-farm.jpg";
import storage from "./media/storage.PNG";
import nem from "./media/cpuc.jpg";
import AddressMap from './AddressMap'; // Import the new component

class About extends React.Component {

  componentDidMount() {
    document.title = "About Us | PV Solar";
  }
  
  render() {
    return (
      <div>
        <div className="about-container">
          <div className="block about">
            About
          </div>
          {/* <div className="block">
              PV Solar Energy is proud to be led by a Professional Engineer (PE) licensed in Electrical Power in California, ensuring top-tier expertise in solar design and installation for San Diego County.
          </div> */}
          <div className="block split">
            <div className="half text">
              <div className="content">
                Location
                <div className="subtext">We proudly serve the entire San Diego County, including Chula Vista, Oceanside, Escondido, Carlsbad, Jamul, Bonita, Del Mar, La Jolla, and more.</div>
              </div>
            </div>
            <div className="half">
              <AddressMap />
            </div>
          </div>
          <div className="block split">
            <div className="half">
              <img src={solar} alt="Solar Farm" className="full-image"/>
            </div>
            <div className="half text">
              <div className="content">
                Solar Panels
                <div className="subtext">Transform your home with our advanced, sustainable solar technology, reducing energy costs and enhancing home value through eco-friendly, long-term savings. Embrace renewable energy for efficient, self-sufficient power, leading the way in energy innovation and eco-conscious living.</div>
              </div>
            </div>
          </div>
          <div className="block split">
            <div className="half text">
              <div className="content">
                Batteries
                <div className="subtext">Enhance energy independence and savings with our advanced battery systems, designed for outage protection and grid support, complete with convenient remote control and instant alerts.</div>
              </div>
            </div>
            <div className="half">
              <img src={storage} alt="Enphase Energy Storage" className="full-image"/>
            </div>
          </div>
          <div className="block split">
            <div className="half">
              <img src={nem} alt="California Public Utilities Commission" className="full-image"/>
            </div>
            <div className="half text">
              <div className="content">
                NEM 3.0
                <div className="subtext">Under NEM 3.0, while compensation for solar energy drops from $0.30 to $0.08 per kWh, pairing solar panels with batteries maximizes benefits. This combination allows homeowners to store and use their solar energy efficiently, reducing grid dependence and enhancing sustainability, thus offsetting the reduced feed-in tariffs.</div>
              </div>
            </div>
          </div>
        </div>

        <div className="mobile-about-container">
          <div className="mobile-block about">
            About
            {/* <div className="subtext">
              PV Solar Energy is proud to be led by a Professional Engineer (PE) licensed in Electrical Power in California, ensuring top-tier expertise in solar design and installation for San Diego County.
            </div> */}
          </div>
          <div className="mobile-block">
            <AddressMap />
          </div>
          <div className="mobile-block text">
            <div className="content">
              Location
              <div className="subtext">We proudly serve the entire San Diego County, including Chula Vista, Oceanside, Escondido, Carlsbad, Jamul, Bonita, Del Mar, La Jolla, and more.</div>
            </div>
          </div>
          <div className="mobile-block">
            <img src={solar} alt="solar" className="full-image"/>
          </div>
          <div className="mobile-block text">
            <div className="content">
              Solar Panels
              <div className="subtext">Transform your home with our advanced, sustainable solar technology, reducing energy costs and enhancing home value through eco-friendly, long-term savings. Embrace renewable energy for efficient, self-sufficient power, leading the way in energy innovation and eco-conscious living.</div>
            </div>
          </div>
          <div className="mobile-block">
            <img src={storage} alt="storage" className="full-image"/>
          </div>
          <div className="mobile-block text">
            <div className="content">
              Batteries
              <div className="subtext">Enhance energy independence and savings with our advanced battery systems, designed for outage protection and grid support, complete with convenient remote control and instant alerts.</div>
            </div>
          </div>
          <div className="mobile-block">
            <img src={nem} alt="cpuc" className="full-image"/>
          </div>
          <div className="mobile-block text">
            <div className="content">
              NEM 3.0
              <div className="subtext">Under NEM 3.0, while compensation for solar energy drops from $0.30 to $0.08 per kWh, pairing solar panels with batteries maximizes benefits. This combination allows homeowners to store and use their solar energy efficiently, reducing grid dependence and enhancing sustainability, thus offsetting the reduced feed-in tariffs.</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;