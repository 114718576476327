import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./css/navbar.css";
import { NavLink, useLocation } from "react-router-dom";
import image from "./media/result.svg";

export default function Navbar() {
  const location = useLocation();
  const [isOnGetQuote, setIsOnGetQuote] = useState(false);
  const [isOnResidential, setIsOnResidential] = useState(false);
  const [isOnCommercial, setIsOnCommercial] = useState(false);
  const [isOnDesign, setIsOnDesign] = useState(false);
  const [isOnAbout, setIsOnAbout] = useState(false);
  const [isOnSolar, setIsOnSolar] = useState(false);
  const [isOnBattery, setIsOnBattery] = useState(false);
  const [isOnMaintenance, setIsOnMaintenance] = useState(false);
  const [isOnRefer, setIsOnRefer] = useState(false);
  const [portfolioDropdown, setPortfolioDropdown] = useState(false);
  const [companyDropdown, setServicesDropdown] = useState(false);
  const [productDropdown, setProductDropdown] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const togglePortfolioDropdown = () => {
    setPortfolioDropdown(!portfolioDropdown);
  }

  const toggleServicesDropdown = () => {
    setServicesDropdown(!companyDropdown);
  }

  const toggleProductDropdown= () => {
    setProductDropdown(!productDropdown);
  }

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setIsOnGetQuote(location.pathname === "/solar-quote");
    setIsOnResidential(location.pathname === "/residential");
    setIsOnAbout(location.pathname === "/about");
    setIsOnCommercial(location.pathname === "/commercial");
    setIsOnSolar(location.pathname === "/solar-panels");
    setIsOnBattery(location.pathname === "/battery-storage");
    setIsOnMaintenance(location.pathname === "/maintenance");
    setIsOnRefer(location.pathname === "/refer-and-earn");
    setIsOnDesign(location.pathname === "/design");

    setPortfolioDropdown(false);
    setServicesDropdown(false);
    setProductDropdown(false);
  }, [location]);

  return (
    <div class="parent-container">

      <div class="desktop-nav-container"> 
        <nav className="navbar navbar-expand-lg navbar-light">
          <NavLink to="/">
            <img class="nav-logo" src={image}></img>
          </NavLink>

          <div className={
              (isOnGetQuote || isOnResidential || isOnCommercial || isOnDesign || isOnAbout 
                || isOnSolar || isOnBattery || isOnMaintenance || isOnRefer) ? 
              "internal-container white-background" : 
              "internal-container"
          }>
            <ul className="links">

              <li className ="dropdown">
                <span class="dropbtn" onClick={togglePortfolioDropdown}>
                  Portfolio
                  <span class="arrow"></span>
                </span>
                {portfolioDropdown && (
                  <div className={`dropdown-content ${portfolioDropdown ? 'show' : ''}`}>
                      <NavLink className = "dropdown-item"
                      to="/residential" onClick={() => setPortfolioDropdown(false)}>
                      Residential
                      </NavLink>

                      <NavLink className = "dropdown-item"
                      to="/commercial" onClick={() => setPortfolioDropdown(false)}>
                      Commercial
                      </NavLink>
                  </div>
                )}
              </li>

              <li class="dropdown">
                <span class="dropbtn" onClick={toggleServicesDropdown}>
                  Services
                  <span className="arrow"></span>
                </span>
                {companyDropdown && (
                  <div className={`dropdown-content ${companyDropdown ? 'show' : ''}`}>
                      <NavLink className = "dropdown-item"
                      to="/design" onClick={() => setServicesDropdown(false)}> 
                      Design & Installation 
                      </NavLink>

                      <NavLink className = "dropdown-item"
                      to="/maintenance" onClick={() => setServicesDropdown(false)}> 
                      Maintenance 
                      </NavLink>
                  </div>
                )}
              </li>

              {/* <li class="dropdown">
                <span class="dropbtn" onClick={toggleProductDropdown}>
                  Products
                  <span className="arrow"></span>
                </span>
                {productDropdown && (
                  <div className={`dropdown-content ${productDropdown ? 'show' : ''}`}>
                      <NavLink className = "dropdown-item"
                      to="/solar-panels" onClick={() => setProductDropdown(false)}> 
                      Solar Panels 
                      </NavLink>

                      <NavLink className = "dropdown-item"
                      to="/battery-storage" onClick={() => setProductDropdown(false)}> 
                      Battery Storage 
                      </NavLink>
                  </div>
                )}
              </li> */}

              <li className="maintenance-link">
                <NavLink
                  to="/about">
                  About
                </NavLink>
              </li>

              <li className="refer-link">
                <NavLink
                  to="/refer-and-earn">
                  Refer & Earn
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink className="nav-link" to="/solar-quote">
                  Request Quote
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
      </div>

      {/* className={isOpen ? "mobile-nav-container open" : "mobile-nav-container"} */}
      <div className="mobile-nav-container">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="nav-logo-container">
              <NavLink to="/">
                  <img className="nav-logo-mobile" src={image} alt="logo"></img>
              </NavLink>
          </div>

          <div className={
              `${isOpen ? "hamburger-menu open" : "hamburger-menu"} ${isOnGetQuote || isOnResidential || isOnDesign || 
                isOnAbout || isOnCommercial || isOnMaintenance || isOnRefer? "white-background" : ""}`
          } onClick={toggleMenu}>
            <div></div>
            <div></div>
            <div></div>
          </div>


          <div className={isOpen ? "slide-in" : "slide-out"}>
            <ul className="mobile-nav" onClick={() => setIsOpen(false)}>
              <li>
                <NavLink className="nav-link" to="/">
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" to="/about">
                  About Us
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" to="/solar-quote">
                  Get Quote
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" to="/residential">
                  Residential
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" to="/commercial">
                  Commercial
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" to="/design">
                  Design & Installation
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" to="/maintenance">
                  Maintenance
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" to="/refer-and-earn">
                  Refer & Earn
                </NavLink>
              </li>
            </ul>
          </div> 
        </nav>
      </div>
    </div>
  );
}
