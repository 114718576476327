import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./css/footer.css";

export default function Footer() {

  return (
    <footer className="app-footer">
        <a className="footer-a" href="/about">Photovoltaic Power Systems LLC &copy; 2025 </a>
        <div className="footer-contact">
          <a className="footer-stuff">DBA: PV Solar</a>
          <a className="footer-stuff" href="https://www.cslb.ca.gov/OnlineServices/CheckLicenseII/LicenseDetail.aspx?LicNum=1107091">
            CA C-10 License #1107091
          </a>
          <a className="footer-stuff">
            PE: Electrical Engineer - Power, California
          </a>
          <a href="mailto:hector@pvsolar.energy" className="footer-email">hector@pvsolar.energy</a>
          <a href="tel:+16199320537" className="footer-phone">+1-619-932-0537</a>
          <a className="footer-stuff">3960 W Point Loma Blvd. Ste. H, San Diego CA 92110</a>
        </div>

        <div className="footer-social-media">
          <a href="https://x.com/pvsolar_" target="_blank" className="footer-icon">
              <i className="fab fa-twitter"></i>
          </a>
          <a href="https://www.instagram.com/pvsolar_/" target="_blank" className="footer-icon">
              <i className="fab fa-instagram"></i>
          </a>
        </div>
    </footer>

  );
}
